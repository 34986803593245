import "vite/modulepreload-polyfill";

import { initializeApp } from "@js/vue/app.js";
initializeApp();

import { flipCoins } from "@js/buttons.js";
flipCoins();

import { updateCursor, setupInitialShimmers } from "@js/shimmer.js";
document.addEventListener("mousemove", updateCursor);
setupInitialShimmers();

import "trig-js";

function setVh() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
}

setVh();
window.addEventListener("resize", setVh);
